.container {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
}

html,
body,
#root {
  /* height: 100%; */
  margin: 0;
  padding: 0;
  background-color: #1F2833;
}

.App {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
}

.NavigationBar {
  flex: 0 0 auto;
}

.LandingPage {
  flex: 1 0 auto;
  overflow: hidden;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}
