.landing {
    background-color: #1F2833;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    position: relative;
    top: 150px;
  }
  
  .landing-title {
    color: white;
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    margin-bottom: 32px;
  }
  
  .landing-text {
    color: white;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    max-width: 800px;
    margin: 0 16px;
  }

  .landing-button-right {
    border: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #263342;
    /* width: 40px; */
    height: 40px;
    border-radius: 10px;
    z-index: 1;
    transition: all 0.3s ease;
    color: white;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .landing-button-right:hover {
    background-color: #1a2631;
  }
  
  .landing-button-right:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }