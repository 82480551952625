.takes-slider {
    width: 100%;
    height: 100vh;
    margin: auto;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  

  .arrow {
    position: absolute;
    top: calc(50% - 100px);
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    animation: pulse 2s infinite;
    /* background-color: blue; */
  }

  .arrow.prev {
    left: -3px;
  }

  .arrow.next {
    right: -3px;
  }

  .arrow img {
    width: 20px;
    height: 20px;
  }

  .votes-detail {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }