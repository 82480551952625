.votes-bar {
/* background-color: rgb(53, 221, 76); */
margin-left: 15px;
margin-right: 8px; /* -7px: Account for the margin-right spacing of the individual bars. */
height: 8px;
max-width: 500px;
}

.bar-element {
float: left;
height: 100%;
}
  
.bar-element-drawn {
height: 100%;
border-radius: 3px;
position: relative;
/* top: 50%;
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%); */
}

.bar-element-drawn.stronglyDisagree {
background-color: #DF4640;
margin-right: 7px;
}
.bar-element-drawn.somewhatDisagree {
background-color: #E8A532;
margin-right: 7px;
}
.bar-element-drawn.neutral {
background-color: #F5CD49;
margin-right: 7px;
}
.bar-element-drawn.somewhatAgree {
background-color: #8EC94D;
margin-right: 7px;
}
.bar-element-drawn.stronglyAgree {
background-color: #43BC55;
margin-right: 7px;
}

.bar-label-no-votes {
color: #8B97A5;
font-size: 12px;
font-family: 'Poppins', sans-serif;
font-weight: 400;
margin: 0;
max-width: 200px;
text-align: left;
}
  