
.onboarding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  position: relative;
  top: 50px;
}
  
.onboarding__content {
  position: relative;
  width: 100%;
  height: 100%;
}

.onboarding__steps {
  position: relative;
  width: 100%;
  height: 100%;
}

.onboarding__content__fade-enter {
  opacity: 0;
}

.onboarding__content__fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.onboarding__content__fade-exit {
  opacity: 1;
}

.onboarding__content__fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.onboarding-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
}

.onboarding-button {
  border: none;
  position: fixed;
  bottom: 20px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #263342;
  height: 40px;
  border-radius: 10px;
  z-index: 1;
  transition: all 0.3s ease;
  color: white;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.onboarding-button.button-left {
  left: 20px;
}

.onboarding-button.button-right {
  right: 20px;
}

/* .onboarding__button__right {
  border: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #263342;
  height: 40px;
  border-radius: 10px;
  z-index: 1;
  transition: all 0.3s ease;
  color: white;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
} */

.onboarding-button:hover {
  background-color: #1a2631;
}

.onboarding-button:disabled {
  color: #8B97A5;
  cursor: not-allowed;
}

/* .onboarding__button:first-child {
  margin-right: 10px;
} */