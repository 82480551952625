.take {
/* height: 100vh; */
color: white;
font-size: 30px;
font-family: 'Poppins', sans-serif;
font-weight: medium;
text-align: center;
/* display: flex; */
justify-content: center;
align-items: center;
}

.take-card {
margin: 15px 15px 0 15px;
background-color: #27323F;
border-radius: 10px;
display: flex;
flex-direction: row;
align-items: center;
}

.take-text {
    position: relative;
    color: white;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    /* max-width: 800px; */
    padding: 10px 30px 0px 30px;
    margin: 15px 0 10px 0;
}

.take-topic-container {
    /* margin: 0 30px; */
    /* padding: 5px; */
    display: flex;
    align-items: flex-start;
    padding: 0 30px;
    margin: 0 0 20px 0
}

.take-topic-container-inner {
    display: inline-block;
    background-color: #1F2833;
    border-radius: 5px;
    padding: 2px 5px;
}

.take-topic {
    margin: 0;
    text-align: left;
    font-weight: 550;
    font-size: 11px;
}

.take-content {
width: 100%;
/* display: flex; */
flex-direction: column;
align-items: center;
}

.take-user {
position: relative;    
display: flex;
flex-direction: row;
align-items: center;
padding: 0px 30px 0px 30px;
}

.take-user-info {
flex: 1;
display: flex;
flex-direction: column;
justify-content: center;
margin-left: 0px;
text-align: left;
}

.take-user-name {
color: white;
font-size: 15px;
font-family: 'Poppins', sans-serif;
font-weight: 500;
margin: 0;
}

.take-user-title {
color: #8B97A5;
font-size: 12px;
font-family: 'Poppins', sans-serif;
font-weight: 400;
line-height: 1.3;
margin: 0;
max-width: 200px;
}

.take-user-img {
height: 50px;
width: 50px;
border-radius: 15px;
margin-right: 15px;
}

.take-votes {
margin-top: 25px;
margin-bottom: 5px;
padding-top: 5px;
padding-bottom: 5px;
border-top: 1px solid #8B97A5;
/* height: 90px; */
width: 100%;
}

.take-votes-group {
margin: 0px 15px 0px 15px;
padding: 0px 0px 0px 0px;
max-width: 500px;
}

.take-votes-label {
color: #fff;
font-size: 12px;
font-family: 'Poppins', sans-serif;
font-weight: 400;
margin: 0;
max-width: 200px;
text-align: left;
margin-left: 15px;
margin-top: 10px;
margin-bottom: 5px;
}

.take-votes-info-label {
    font-size: 9px;
    font-weight: 500;
    margin: 15px 0 0 0;
}

.take-voting-bar {
height: 40px;
margin: 10px 10px 10px 15px;
display: flex;
}

.take-voting-step {
flex: 1;
height: 100%;
border-radius: 5px;
margin-right: 5px;
/* padding: 0 2px; */
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
transition: opacity 0.5s ease;
overflow: hidden;
opacity: 1;
}

.take-voting-step.selected {
opacity: 1;
}

.take-voting-step.unselected {
opacity: 0.2;
}

.take-voting-step.stronglyDisagree {
border-radius: 10px 4px 4px 10px;
background-color: #DF4640;
}
.take-voting-step.somewhatDisagree {
background-color: #E8A532;
}
.take-voting-step.neutral {
background-color: #F5CD49;
}
.take-voting-step.somewhatAgree {
background-color: #8EC94D;
}
.take-voting-step.stronglyAgree {
border-radius: 5px 10px 10px 5px;
background-color: #43BC55;
}

.take-voting-step-label {
/* height: 100%; */
margin: 5px 10px 5px 10px;
padding: 0;
color: #fff;
font-size: 10px;
font-family: 'Poppins', sans-serif;
font-weight: 500;
line-height: 1.2;
text-align: center;
max-width: 100%;
overflow: hidden;
text-overflow: ellipsis;
}