.dropdown {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 100%;
  z-index: 1000;
}

.dropbtn {
  color: white;
  background-color: #1F2833;
  height: 100%;
  font-size: 30x;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  border: none;
  cursor: pointer;
  width: 100%;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #1F2833;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}

.dropdown-content.show {
  opacity: 1;
  pointer-events: auto;
}

.dropdown-item {
  color: #f1f1f1;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 30x;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  background-color: #27323F;
  border: none;
  cursor: pointer;
  position: relative;
}