.votes-detail {
  /* position: fixed; */
  top: 40px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1F2833;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  /* transform: translateY(100%);
  transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1.01); */
}

.votes-detail.show {
  opacity: 1;
  visibility: visible;
  /* transform: translateY(0%); */
}

.votes-content {
  height: 100%;
  width: 100%;
  position: relative;
}

.votes-detail-header {
height: 60px;
display: flex;
align-items: center;
justify-content: space-between;
}

.votes-group-detail-label {
height: 20px;
margin-left: 30px;
max-width: 200px;
color: #fff;
font-family: 'Poppins', sans-serif;
font-size: 12px;
font-weight: 400;
align-self: stretch;
}

.close-button {
height: 60px;
padding: 0px 30px 0px 30px;
border: none;
background-color: #1F2833;
color: #fff;
font-family: 'Poppins', sans-serif;
font-size: 12px;
font-weight: 600;
text-align: center;
align-self: stretch;
}

.close-button:hover {
color: #f5f5f5;
}

.votes-chart {
height: 300px;
max-width: 500px;
position: relative;
}

.votes-chart-detail-label {
height: 20px;
margin-top: -140px;
color: #fff;
font-family: 'Poppins', sans-serif;
font-size: 12px;
font-weight: 400;
text-align: center;
}

.votes-groups {
position: relative;
margin-top: -120px;
padding: 20px 0px 60px 0px;
z-index: 1;
}

.votes-detail-group {
margin: 5px 15px 0px 15px;
padding: 1px 0px 10px 0px;
max-width: 500px;
transition: background-color 0.3s ease;
border-radius: 7px;
}

.votes-detail-group.selected {
border-radius: 7px;
background-color: #263342;
}

.votes-detail-parent-group-label, .votes-detail-group-label {
color: #fff;
font-family: 'Poppins', sans-serif;
margin: 0;
max-width: 200px;
text-align: left;
margin-left: 15px;
}

.votes-detail-parent-group-label {
font-size: 14px;
font-weight: 600;
margin-top: 25px;
margin-left: 30px;
}

.votes-detail-group-label {
font-size: 12px;
font-weight: 400;
margin-top: 5px;
margin-bottom: 5px;
}
