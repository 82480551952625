.onboarding-question {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 80vh;
}

.onboarding-question-title {
color: white;
font-size: 25px;
font-family: 'Poppins', sans-serif;
font-weight: bold;
margin-bottom: 32px;
}
  
.onboarding-question-text {
color: white;
font-size: 18px;
font-family: 'Poppins', sans-serif;
text-align: center;
max-width: 800px;
margin: 0 16px;
}

.onboarding-question-options-wrapper {
width:100%;
}

.onboarding-question-options {
width: 100%;
max-height: 40vh;
margin-top: 32px;
display: flex;
flex-direction: column;
align-items: center;
overflow-y: scroll;
overflow-x: hidden;
scrollbar-width: none;
-ms-overflow-style: none;
}
    
.onboarding-question-options::-webkit-scrollbar {
display: none;
}

.onboarding-question-option {
    width: 100%;
    display: flex;
    justify-content: center;
    /* padding: 7px 0;
    margin: 0; */
    cursor: pointer;
    transition: background-color 0.2s ease;
    list-style: none;
    border-radius: 12px;
  }
  
  .onboarding-question-option-content {
    color: #8B97A5;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    padding: 5px 15px;
    margin: 5px;
    border-radius: 12px;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  
  .onboarding-question-option:hover .onboarding-question-option-content {
    background-color: #263342;
    color: #fff;
  }
  
  .onboarding-question-option.selected .onboarding-question-option-content {
    background-color: #263342;
    color: #fff;
  }