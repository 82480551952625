.navbar {
    display: flex;
    align-items: center;
    background-color: #27323F;
    height: 40px;
    width: 100%;
    color: white;
    position: relative;
  }
  
  .navbar-title {
    margin-left: 20px;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    flex: 1;
  }
  
  .navbar-separator {
    margin: 0;
    border: none;
    border-top: 1px solid gray;
    position: absolute;
    z-index: 1001;
    bottom: 0;
    left: 0;
    right: 0;
  }